<!-- 导入表编辑 -->
<template>
    <div class="EditImportTable">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">导入表编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" id="form-1" :rules="rules" class="" inline label-width="150px">
            <el-form-item label="导入机构">
                <el-input v-model="form.mechanism" placeholder="请输入导入机构"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" placeholder="请输入姓名" @input="ConvertName"></el-input>
            </el-form-item>
            <el-form-item label="拼音名">
                <el-input v-model="form.pinyin" placeholder="请输入拼音名"></el-input>
            </el-form-item>
            <el-form-item label="国籍" prop="nationality">
                <el-input v-model="form.nationality" placeholder="请输入国籍"></el-input>
            </el-form-item>
            <el-form-item label="民族" prop="nation">
                <el-input v-model="form.nation" placeholder="请输入民族"></el-input>
            </el-form-item>
            <el-form-item label="证件号" prop="IDNumber" >
                <el-input v-model="form.IDNumber" placeholder="请输入证件号" @blur="Losefocus()"></el-input>
            </el-form-item>
            <el-form-item label="出生日期" prop="birthday">
                <el-input v-model="form.birthday" placeholder="请输入出生日期" disabled></el-input>
                <!-- <el-date-picker v-model="form.birthday"  type="date"
                 placeholder="请选择出生日期" >
                </el-date-picker> -->
            </el-form-item>
            <el-form-item label="等级">
                <el-input v-model="form.grade" placeholder="请输入等级"></el-input>
            </el-form-item>
            <el-form-item label="科目">
                <el-input v-model="form.subject" placeholder="请输入科目"></el-input>
            </el-form-item>
            <el-form-item label="移动电话" prop="telephone">
                <el-input v-model="form.telephone" placeholder="请输入移动电话"></el-input>
            </el-form-item> 
            <el-form-item label="说明">
                <el-input v-model="form.explain" 
                type="textarea" class="textArea-width" placeholder=""></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;"  @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                 mechanism:"",//导入机构
                 name:"",//姓名
                 pinyin:"",//拼音名
                 nationality:"",//国籍
                 nation:"",//民族
                 birthday:"",//出生日期
                 grade:"",//等级
                 subject:"",//科目
                 telephone:"",//移动电话
                 IDNumber:"",//证件号
                 explain:"",//说明
               },
                rules:{
                    name:[
                         { required: true, message: '姓名不能为空', trigger: 'blur' }
                    ],
                    nationality:[
                         { required: true, message: '国籍不能为空', trigger: 'blur' }
                    ],
                    nation:[
                         { required: true, message: '民族不能为空', trigger: 'blur' }
                    ],
                    nation:[
                         { required: true, message: '民族不能为空', trigger: 'blur' }
                    ],
                    birthday:[
                         { required: true, message: '出生日期不能为空', trigger: 'blur' }
                    ],
                    telephone:[
                         { required: true, message: '移动电话不能为空', trigger: 'blur' }
                    ],
                    IDNumber:[
                         { required: true, message: '证件号不能为空', trigger: 'blur' },
                         {
                             min: 18, 
                             max: 18, 
                             message: '身份证输入有误！', 
                             trigger: 'blur' 
                         }
                    ],
                },
                bool:false
            }
        },
        created() {
            this.$request({url: '/api/Examsignup/detail', method: 'POST', data:{
                id:this.$route.query.id
            }}).then(res=>{
                console.log(res.data.data)
                let data = res.data.data
                this.form.mechanism=data.org_id//导入机构
                this.form.name=data.name//姓名
                this.form.pinyin=data.english_name//拼音名
                this.form.nationality=data.nation//国籍
                this.form.nation=data.nation2//民族
                this.form.birthday=data.birthday//出生日期
                this.form.grade=data.level_id//等级
                this.form.subject=data.course_id//科目
                this.form.telephone=data.phome//移动电话
                this.form.IDNumber=data.idcard//证件号
                this.form.explain=data.remark//说明
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        this.$request({url: '/api/Examsignup/edit', method: 'POST', data:{
                            id:this.$route.query.id,
                            org_id:this.form.mechanism,
                            name:this.form.name,
                            english_name:this.form.pinyin,
                            nation:this.form.nationality,
                            nation2:this.form.nation,
                            birthday:this.form.birthday,
                            level_id:this.form.grade,
                            course_id:this.form.subject,
                            phome:this.form.telephone,
                            idcard:this.form.IDNumber,
                            remark:this.form.explain
                        }}).then(res=>{
                            console.log(res)
                            if (res.code == 1) {
                                    this.$message({
                                        message: '修改成功',
                                        type: 'success'
                                    })
                                    setTimeout(() => {
                                        this.goBack()
                                    }, 1500)
                                }else{
                                    this.$message({
                                        message: res.msg,
                                        type: 'error'
                                    })
                                    setTimeout(() => {
                                        this.goBack()
                                    }, 1500)
                                }
                            }).catch(()=>{
                                this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack()
                this.$refs[form].resetFields();
            },
            Losefocus(){ //失去焦点赋值生日
                // 赋值生日
                let IDNumber = this.form.IDNumber
                if(IDNumber.trim().length==18){
                    // console.log(IDNumber.slice(6,10)+'-'+IDNumber.slice(10,12)+'-'+IDNumber.slice(12,14))
                  this.form.birthday = IDNumber.slice(6, 10) + '-' + IDNumber.slice(10, 12) + '-' + IDNumber.slice(12, 14)
                }

            },
            ConvertName(){//转换拼音    
                // this.form.name
                // console.log(this.$pinyin.getFullChars(this.form.name));
                this.form.pinyin=this.$pinyin.getFullChars(this.form.name)
                // console.log(pinyin.getCamelChars('张三'));
            }
        }
    }
</script>

<style scoped="scoped">
    .EditImportTable{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    ::v-deep .el-input.is-disabled .el-input__inner{
        color: #333333;
        background-color: #fff;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
        
    }
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
